import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import logServices from "../../services/logService";
import { GoDotFill } from "react-icons/go";
import {
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
  Icon,
  Stack,
  Chip,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import { HiFolderArrowDown } from "react-icons/hi2";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { moment } from "moment";
import {
  Exportoptions,
  StyledCardShadow,
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTableTitle,
  StyledTabsGroup,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import StyledTooltip from "../shared/StyledTooltip";
import {
  useAuditLogMutation,
  useVehicleSummaryMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useLocation, useNavigate } from "react-router-dom";

const text =
  "This Report gives the Summarized data about the vehicle movement for a selected time period. Energy Efficiency is calculated for vehicles that have traveled more than 10km per day";

const VehicleSummaryReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPeriod, setSelectedPeriod] = useState(1);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const theme = useTheme();

  useEffect(async () => {
    // await fetchVehicleSummaryReportDetails();
    // var request = {};
    // request.message = "Reports > VehicleSummary";
    // request.action = "View";
    // request.featureName = "VehicleSummary";
    // request.customerName = "All";
    // request.fleetName = "All";
    // request.result = "Success";
    // request.access = "Portal";
    // request.severity = "INFO";
    // request.orgId = globalOrgId;
    // request.userId = globaluserId;
    // request.orgName = globalOrgName;
    // request.userName = globalUserName;
    // const responseData = await logServices.saveAuditLogDetails(request);
    auditPayload.message = "Reports >  Vehicle Summary";
    auditPayload.action = "View";
    auditPayload.featureName = " Vehicle Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
        show: false,
      },
      {
        Header: "VIN",
        accessor: "vinNumber",
      },
      {
        Header: "CustomerName",
        accessor: "customerName",
      },
      {
        Header: "Total Hours (hh:mm)",
        columns: [
          {
            Header: "Moving",
            accessor: "moveMinutes",
          },
          {
            Header: "Stop",
            accessor: "stopMinutes",
          },
          {
            Header: "Offline",
            accessor: "offlineMinutes",
          },
        ],
      },
      {
        Header: "Distance Traveled (km)",
        columns: [
          {
            Header: "Start Odometer (km)",
            accessor: "startOdometer",
          },
          {
            Header: "End Odometer (km)",
            accessor: "endOdometer",
          },
          {
            Header: "Distance Traveled (km)",
            accessor: "distanceTraveled",
            Cell: ({ row }) => {
              if (resellerId === 110224 &&  row.original.distanceTraveled !== 0 ) {
                return (
                  <Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Icon
                        sx={{
                          width: "auto",
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                        color={
                          row.original.distanceTraveled !== 0 && (row.original.distanceTraveled >=
                          row.original.targetedDistance)
                            ? "success" :  "error" 
                        }
                      >
                        <GoDotFill />
                      </Icon>
                      <Typography>{row.original.distanceTraveled}</Typography>
                    </Stack>
                  </Box>
                );
              } else {
                return row.original.distanceTraveled;
              }
            },
          },
          {
            Header: "Target Distance (km)",
            accessor: "targetedDistance",
          },
        ],
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Consumed (kWh)"
                : "Energy Consumed (Wh)"
            }
            title={
              "Total Energy Consumed including all days irrespective of distance traveled"
            }
          />
        ),
        accessor: "energyConsumed",
      },
      {
        Header: "Stop Energy Consumed (kWh)",
        accessor: "stopEnergyConsumedinpark",
      },
      {
        Header: "Move Energy Consumed (kWh)",
        accessor: "movingEnergyConsumed",
      },

      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (km/kWh)"
                : "Energy Efficiency (km/Wh)"
            }
            title={
              "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
            }
          />
        ),
        accessor: "batteryEfficiency",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (kWh/km)"
                : "Energy Efficiency (Wh/km)"
            }
            title={
              "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
            }
          />
        ),
        accessor: "efficiencyPerKM",
        Cell: ({ row, column, cell }) => {
          const efficeincy = row.original.efficiencyPerKM;

          return row.original.efficiencyPerKM !== "" &&
            row.original.efficiencyPerKM !== null &&
            row.original.efficiencyPerKM !== "-"
            ? parseFloat(efficeincy).toFixed(2)
            : "-";
        },
      },
      {
        Header: "Charging Details",
        columns: [
          {
            Header: "Count",
            accessor: "chargeCount",
            Cell: ({ row }) => {
              return row.original.chargeCount !== 0
                ? row.original.chargeCount
                : "-";
            },
          },
          {
            Header: "Duration (hh:mm)",
            accessor: "chargeMins",
          },
        ],
      },
      {
        Header: "Harsh Usage (count)",
        columns: [
          {
            Header: "Acceleration",
            accessor: "harshAccelerationCount",
          },
          {
            Header: "Brake",
            accessor: "harshbrakeCount",
          },
          {
            Header: "Cornering",
            accessor: "rashTurningCount",
          },
        ],
      },
      {
        Header: "OverSpeeding",
        columns: [
          {
            Header: "Count",
            accessor: "overSpeedCount",
          },
          {
            Header: "Duration (hh:mm)",
            accessor: "overSpeedDurationstr",
          },
          {
            Header: "Distance (km)",
            accessor: "overSpeedDistance",
          },
          {
            Header: "SOC(%) Consumed",
            accessor: "overSpeedSOCConsumed",
          },
        ],
      },
      {
        Header: "Maximum Speed (km/h)",
        accessor: "maxSpeed",
      },
      {
        Header: (
          <StyledTooltip
            header={"EV"}
            title={"Assumed Rs.10/Electrical unit"}
          />
        ),
        id: "EV",
        columns: [
          {
            // Header: "EB units",
            Header: (
              <StyledTooltip
                header={"EB units"}
                title={
                  "1 unit of electricity=1kWh <br> A kWh is the standard unit used by energy suppliers to calculate electricity bill. One unit refers to the use of 1,000 watts over one hour."
                }
              />
            ),
            accessor: "ebUnits1",
          },
          {
            Header: "EV Cost per km (₹)",
            accessor: "ebCost1",
          },
        ],
      },
      {
        Header: (
          <StyledTooltip
            header={resellerId === 110224 ? "Diesel" : "Petrol"}
            title={"Assumed Rs. 102/Litre"}
          />
        ),
        id: "Petrol",
        columns: [
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Diesel  in Litres"
                    : "Petrol in Litres"
                }
              />
            ),
            accessor: "dieselUnits",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Diesel Cost per km (₹)"
                    : " Petrol Cost per km (₹)"
                }
              />
            ),
            accessor: "dieselCost",
          },
        ],
      },
      {
        Header: "EV Savings",
        columns: [
          {
            Header: "Savings per km (₹)",
            accessor: "savings",
          },
          {
            Header: "Total Savings (₹)",
            accessor: "totalSavings",
          },
        ],
      },

      {
        Header: "Start SOC (%)",
        accessor: "startSoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "endSoc",
      },
    ],
    []
  );

  const customerColumns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
        show: false,
      },
      {
        Header: "VIN",
        accessor: "vinNumber",
      },
      {
        Header: "CustomerName",
        accessor: "customerName",
      },
      {
        Header: "Total Hours (hh:mm)",
        columns: [
          {
            Header: "Moving",
            accessor: "moveMinutes",
          },
          {
            Header: "Stop",
            accessor: "stopMinutes",
          },
          {
            Header: "Offline",
            accessor: "offlineMinutes",
          },
        ],
      },
      {
        Header: "Distance Traveled (km)",
        columns: [
          {
            Header: "Start Odometer (km)",
            accessor: "startOdometer",
          },
          {
            Header: "End Odometer (km)",
            accessor: "endOdometer",
          },
          {
            Header: "Distance Traveled (km)",
            accessor: "distanceTraveled",
            Cell: ({ row }) => {
              if (resellerId === 110224 &&  row.original.distanceTraveled !== 0 ) {
                return (
                  <Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Icon
                        sx={{
                          width: "auto",
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                        color={
                          row.original.distanceTraveled !== 0 && (row.original.distanceTraveled >=
                          row.original.targetedDistance)
                            ? "success" :  "error" 
                        }
                      >
                        <GoDotFill />
                      </Icon>
                      <Typography>{row.original.distanceTraveled}</Typography>
                    </Stack>
                  </Box>
                );
              } else {
                return row.original.distanceTraveled;
              }
            },
          },
          {
            Header: "Target Distance (km)",
            accessor: "targetedDistance",
          },
        ],
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Consumed (kWh)"
                : "Energy Consumed (Wh)"
            }
            title={
              "Total Energy Consumed including all days irrespective of distance traveled"
            }
          />
        ),
        accessor: "energyConsumed",
      },
      {
        Header: "Stop Energy Consumed (kWh)",
        accessor: "stopEnergyConsumedinpark",
      },
      {
        Header: "Move Energy Consumed (kWh)",
        accessor: "movingEnergyConsumed",
      },

      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (km/kWh)"
                : "Energy Efficiency (km/Wh)"
            }
            title={
              "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
            }
          />
        ),
        accessor: "batteryEfficiency",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (kWh/km)"
                : "Energy Efficiency (Wh/km)"
            }
            title={
              "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
            }
          />
        ),
        accessor: "efficiencyPerKM",
        Cell: ({ row, column, cell }) => {
          const efficeincy = row.original.efficiencyPerKM;

          return row.original.efficiencyPerKM !== "" &&
            row.original.efficiencyPerKM !== null &&
            row.original.efficiencyPerKM !== "-"
            ? parseFloat(efficeincy).toFixed(2)
            : "-";
        },
      },
      {
        Header: "Charging Details",
        columns: [
          {
            Header: "Count",
            accessor: "chargeCount",
            Cell: ({ row }) => {
              return row.original.chargeCount !== 0
                ? row.original.chargeCount
                : "-";
            },
          },
          {
            Header: "Duration (hh:mm)",
            accessor: "chargeMins",
          },
        ],
      },
      {
        Header: "Harsh Usage (count)",
        columns: [
          {
            Header: "Acceleration",
            accessor: "harshAccelerationCount",
          },
          {
            Header: "Brake",
            accessor: "harshbrakeCount",
          },
          {
            Header: "Cornering",
            accessor: "rashTurningCount",
          },
        ],
      },
      {
        Header: "OverSpeeding",
        columns: [
          {
            Header: "Count",
            accessor: "overSpeedCount",
          },
          {
            Header: "Duration (hh:mm)",
            accessor: "overSpeedDurationstr",
          },
          {
            Header: "Distance (km)",
            accessor: "overSpeedDistance",
          },
          {
            Header: "SOC(%) Consumed",
            accessor: "overSpeedSOCConsumed",
          },
        ],
      },
      {
        Header: "Maximum Speed (km/h)",
        accessor: "maxSpeed",
      },
      // {
      //   Header: "EV Savings",
      //   columns: [          
      //     {
      //       Header: "Total Savings (₹)",
      //       accessor: "totalSavings",
      //     },
      //   ],
      // },

      {
        Header: "Start SOC (%)",
        accessor: "startSoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "endSoc",
      },
    ],
    []
  );

  const columns1 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
        show: false,
      },
      {
        Header: "VIN",
        accessor: "vinNumber",
      },
      {
        Header: "Total Hours (hh:mm)",
        columns: [
          {
            Header: "Moving",
            accessor: "moveMinutes",
          },
          {
            Header: "Stop",
            accessor: "stopMinutes",
          },
          {
            Header: "Offline",
            accessor: "offlineMinutes",
          },
        ],
      },
      {
        Header: "Distance Traveled (km)",
        columns: [
          {
            Header: "Start Odometer (km)",
            accessor: "startOdometer",
          },
          {
            Header: "End Odometer (km)",
            accessor: "endOdometer",
          },
          {
            Header: "Distance Traveled (km)",
            accessor: "distanceTraveled",
            Cell: ({ row }) => {
              if (resellerId === 110224) {
                return (
                  <Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Icon
                        sx={{
                          width: "auto",
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                        color={
                          row.original.distanceTraveled >=
                          row.original.targetedDistance
                            ? "success"
                            : "error"
                        }
                      >
                        <GoDotFill />
                      </Icon>
                      <Typography>{row.original.distanceTraveled}</Typography>
                    </Stack>
                  </Box>
                );
              } else {
                return row.original.distanceTraveled;
              }
            },
          },
          {
            Header: "Target Distance (km)",
            accessor: "targetedDistance",
          },
        ],
      },
      // {
      //   Header: (
      //     <StyledTooltip
      //       header={
      //         resellerId === 110224
      //           ? "Energy Consumed (kWh)"
      //           : "Energy Consumed (Wh)"
      //       }
      //       title={
      //         "Total Energy Consumed including all days irrespective of distance traveled"
      //       }
      //     />
      //   ),
      //   accessor: "energyConsumed",
      // },
      // {
      //   Header: "Stop Energy Consumed (kWh)",
      //   accessor: "stopEnergyConsumedinpark",
      // },

      // {
      //   Header: (
      //     <StyledTooltip
      //       header={
      //         resellerId === 110224
      //           ? "Energy Efficiency (km/kWh)"
      //           : "Energy Efficiency (km/Wh)"
      //       }
      //       title={
      //         "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
      //       }
      //     />
      //   ),
      //   accessor: "batteryEfficiency",
      // },
      // {
      //   Header: (
      //     <StyledTooltip
      //       header={
      //         resellerId === 110224
      //           ? "Energy Efficiency (kWh/km)"
      //           : "Energy Efficiency (Wh/km)"
      //       }
      //       title={
      //         "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
      //       }
      //     />
      //   ),
      //   accessor: "efficiencyPerKM",
      //   Cell: ({ row, column, cell }) => {
      //     const efficeincy = row.original.efficiencyPerKM;

      //     return row.original.efficiencyPerKM !== "" &&
      //       row.original.efficiencyPerKM !== null &&
      //       row.original.efficiencyPerKM !== "-"
      //       ? parseFloat(efficeincy).toFixed(2)
      //       : "-";
      //   },
      // },
      {
        Header: "Charging Details",
        columns: [
          {
            Header: "Count",
            accessor: "chargeCount",
            Cell: ({ row }) => {
              return row.original.chargeCount !== 0
                ? row.original.chargeCount
                : "-";
            },
          },
          {
            Header: "Duration (hh:mm)",
            accessor: "chargeMins",
          },
        ],
      },
      // {
      //   Header: "Harsh Usage (count)",
      //   columns: [
      //     {
      //       Header: "Acceleration",
      //       accessor: "harshAccelerationCount",
      //     },
      //     {
      //       Header: "Brake",
      //       accessor: "harshbrakeCount",
      //     },
      //     {
      //       Header: "Cornering",
      //       accessor: "rashTurningCount",
      //     },
      //   ],
      // },
      // {
      //   Header: "OverSpeeding",
      //   columns: [
      //     {
      //       Header: "Count",
      //       accessor: "overSpeedCount",
      //     },
      //     {
      //       Header: "Duration (hh:mm)",
      //       accessor: "overSpeedDurationstr",
      //     },
      //   ],
      // },
      // {
      //   Header: "Maximum Speed (km/h)",
      //   accessor: "maxSpeed",
      // },
      // {
      //   Header: (
      //     <StyledTooltip
      //       header={"EV"}
      //       title={"Assumed Rs.10/Electrical unit"}
      //     />
      //   ),
      //   id: "EV",
      //   columns: [
      //     {
      //       // Header: "EB units",
      //       Header: (
      //         <StyledTooltip
      //           header={"EB units"}
      //           title={
      //             "1 unit of electricity=1kWh <br> A kWh is the standard unit used by energy suppliers to calculate electricity bill. One unit refers to the use of 1,000 watts over one hour."
      //           }
      //         />
      //       ),
      //       accessor: "ebUnits1",
      //     },
      //     {
      //       Header: "EV Cost per km (₹)",
      //       accessor: "ebCost1",
      //     },
      //   ],
      // },
      // {
      //   Header: (
      //     <StyledTooltip
      //       header={resellerId === 110224 ? "Disel" : "Petrol"}
      //       title={"Assumed Rs. 102/Litre"}
      //     />
      //   ),
      //   id: "Petrol",
      //   columns: [
      //     {
      //       Header: (
      //         <StyledTooltip
      //           header={
      //             resellerId === 110224
      //               ? "Disel  in Litres"
      //               : "Petrol in Litres"
      //           }
      //         />
      //       ),
      //       accessor: "dieselUnits",
      //     },
      //     {
      //       Header: (
      //         <StyledTooltip
      //           header={
      //             resellerId === 110224
      //               ? "Diesel Cost per km (₹)"
      //               : " Petrol Cost per km (₹)"
      //           }
      //         />
      //       ),
      //       accessor: "dieselCost",
      //     },
      //   ],
      // },
      // {
      //   Header: "EV Savings",
      //   columns: [
      //     {
      //       Header: "Savings per km (₹)",
      //       accessor: "savings",
      //     },
      //     {
      //       Header: "Total Savings (₹)",
      //       accessor: "totalSavings",
      //     },
      //   ],
      // },

      {
        Header: "Start SOC (%)",
        accessor: "startSoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "endSoc",
      },
    ],
    []
  );

  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
    arr.push("batteryEfficiency");
    arr.push("targetedDistance");

    arr.push("stopEnergyConsumedinpark");
    arr.push("overSpeedSOCConsumed");
    arr.push("overSpeedDistance");
    arr.push("customerName");
    arr.push("movingEnergyConsumed");
    initialState1.hiddenColumns = arr;
  }

  //Montra Tap
  const [value, setValue] = useState(0);
  const [dashboardUrl, setDashboardUrl] = useState(null); // Only for montra
  let iframeReload = useRef();
  // const [payload, setPayload] = useState(null);
  // const setloadvalues = () => {
  //   switch (globalOrgType) {
  //     case 8: // login as superadmin and reseller
  //     case 2:
  //       loadrequest.regionId = globalResellerId === 113802 ? 1 : -1;
  //       loadrequest.resellerOrgId = globalOrgId;
  //       loadrequest.dealerId = globalResellerId === 113802 ? 113806 : -1;
  //       loadrequest.customerId = -1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     // case 2: // login as reseller
  //     //     loadrequest.regionId= -1;
  //     //     loadrequest.reselId = globalOrgId;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.customerId = -1;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.orgId = -1; // fleet
  //     //     loadrequest.vehicleId = -1;
  //     //      break;

  //     case 5: // login as Dealer
  //       loadrequest.regionId = 0;
  //       loadrequest.dealerId = globalOrgId;
  //       loadrequest.customerId = -1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 3: // login as customer
  //       loadrequest.regionId = 0;
  //       loadrequest.customerId = globalOrgId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 1: // login as fleet
  //       loadrequest.regionId = 0;
  //       loadrequest.customerId = globalCustomerId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = globalOrgId; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //   }
  // };

  // const fetchVehicleSummaryReportDetails = async () => {
  //   setloadvalues();
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   const fromDate =
  //     format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";

  //   loadrequest.search = "";
  //   loadrequest.startDate = fromDate;
  //   loadrequest.endDate = toDate;
  //   loadrequest.sortDir = "desc";
  //   loadrequest.selperiod = selectedPeriod;
  //   setAssignedval(loadrequest);

  //   const responseData = (
  //     await ReportServices.VehicleSummaryReport(loadrequest)
  //   ).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');
  // const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD hh:mm');

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   // var request = {};
  //   // request.orgId = 1;
  //   // request.vehicleId = 25909;
  //   loadrequest = assignedval;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   setAssignedval(loadrequest);
  //   const responseData = (
  //     await ReportServices.VehicleSummaryReport(loadrequest)
  //   ).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;

  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "VehicleSummaryReport/vehicleSummaryReportAll.xls?regionId=" +
  //       loadrequest.regionId +
  //       "&resellerOrgId=" +
  //       loadrequest.resellerOrgId +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&customerId=" +
  //       loadrequest.customerId +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&size=" +
  //       loadrequest.size +
  //       "&page=" +
  //       loadrequest.page +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&selperiod=" +
  //       loadrequest.selperiod +
  //       "&orderDir=" +
  //       loadrequest.sortDir +
  //       "&search=" +
  //       loadrequest.search;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > VehicleSummary > XLS";
  //     request.action = "View";
  //     request.featureName = "VehicleSummary";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName = globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };

  // const onChangePDF = async () => {
  //   loadrequest = assignedval;

  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "VehicleSummaryReport/vehicleSummaryReportAll.Pdf?regionId=" +
  //       loadrequest.regionId +
  //       "&resellerOrgId=" +
  //       loadrequest.resellerOrgId +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&customerId=" +
  //       loadrequest.customerId +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&size=" +
  //       loadrequest.size +
  //       "&page=" +
  //       loadrequest.page +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&selperiod=" +
  //       loadrequest.selperiod +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&orderDir=" +
  //       loadrequest.sortDir +
  //       "&search=" +
  //       loadrequest.search;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > VehicleSummary > PDF";
  //     request.action = "View";
  //     request.featureName = "VehicleSummary";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName = globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };

  // const handleSearch = async (searchText) => {
  //   setSearchVal(searchText);
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   setAssignedval(loadrequest);
  //   const responseData = (
  //     await ReportServices.VehicleSummaryReport(loadrequest)
  //   ).data;
  //   setResponseData(responseData.data.content);
  // };

  // const handleSubmit = async (data) => {
  //   loadrequest.regionId = data.region;
  //   loadrequest.resellerOrgId = data.reseller;
  //   //loadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.customerId = data.customer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.sortDir = "desc";
  //   // loadrequest.search = "";
  //   loadrequest.selperiod = data.selectedPeriod;
  //   loadrequest.search = searchval;
  //   setAssignedval(loadrequest);
  //   const responseData = (
  //     await ReportServices.VehicleSummaryReport(loadrequest)
  //   ).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // Get Data from New Dropdown

  const [getOverSpeed, { data: vehicleSummaryData, isLoading, isSuccess }] =
    useVehicleSummaryMutation();
    const [payload, setPayload] = useState(null);
    const [rawPayload, setRawPayload] = useState(null);
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setRawPayload(data);
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerOrgId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId !== undefined ? data.fleetId : data.orgId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate !== undefined ? data.fromDate : data.startDate;
    commonPayload.endDate = data.toDate !== undefined ? data.toDate : data.endDate ;
    commonPayload.sortDir = "desc";
    commonPayload.page = 0;
    commonPayload.size = 25;
    if(commonPayload?.selectedPeriodName==="Last 7 Days"){
      commonPayload.selperiod = 7;
    }else{
      commonPayload.selperiod = data.selectedPriod !== undefined ? data.selectedPriod : data.selperiod;
    }   
    //Service Call
    dashboardGen(data);
    setPayload(commonPayload);
    getOverSpeed(commonPayload);


    auditPayload.message = "Reports > Vehicle Summary > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Summmary Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };


   const dashboardGen = (formData) => {

    var regionId = "";
    var customerId = "";
    var dealerId = "";
    var fleetId = "";
    var vehicleId = "";
    if (formData !== null) {
      if (formData?.regionId !== "-1") {
        regionId = '"REGION_ID"=' + formData?.regionId + " and ";
      } else {
        regionId = '"REGION_ID"!=5' + "and";
      }

      if (formData?.dealerId !== "-1") {
        dealerId = '"DEALER_ID"=' + formData?.dealerId + " and ";
      } else {
        dealerId = "";
      }

      if (formData?.customerId !== "-1") {
        customerId = '"CUSTOMER_ID"=' + formData?.customerId + " and ";
      } else {
        customerId = "";
      }

      if (formData?.fleetId !== "-1" && payload?.orgId !== '-1') {
        let fleet = formData?.fleetId !== undefined ? formData?.fleetId : payload?.orgId
        fleetId = '"org_id"=' + fleet + " and ";
      } else {
        fleetId = "";
      }
      if (formData?.vehicleId !== "-1") {
        vehicleId = '"vehicle_id"=' + formData?.vehicleId + " and ";
      } else {
        vehicleId = "";
      }
    }

    //Set URL
    setDashboardUrl(
      process.env.REACT_APP_PROD_ENV === 'true' ?
      "https://analytics.zoho.in/open-view/353263000000054294?ZOHO_CRITERIA=" + 
      regionId +
      dealerId +
      customerId +
      fleetId +
      vehicleId +
      " RESELLER_ID =" +
      resellerId + ' AND "VW_zoho_monthly_vs_DW"."z_created_date" BETWEEN ' + `'${formData?.fromDate !== undefined ? formData?.fromDate :payload?.startDate }'` + " AND " + `'${formData?.toDate !== undefined ? formData?.toDate : payload?.endDate}'` +
      ""
      :
        "https://analytics.zoho.in/open-view/353263000001330822?ZOHO_CRITERIA=" +
      regionId +
      dealerId +
      customerId +
      fleetId +
      vehicleId +
      " RESELLER_ID =" +
      resellerId + ' AND "VW_zoho_monthly_vs_DW"."z_created_date" BETWEEN ' + `'${formData?.fromDate !== undefined ? formData?.fromDate :payload?.startDate }'` + " AND " + `'${formData?.toDate !== undefined ? formData?.toDate : payload?.endDate}'` +
      ""
    )

   
  }

    //Export PDF & Excel

    const onChangeXcl = async () => {
      try {
        if(payload.resellerOrgId === 110224){
          var url =
          process.env.REACT_APP_API_BASE_URL +
          "VehicleSummaryReport/VehicleSummaryReportcsv.csv?regionId=" +
          payload.regionId +
          "&resellerOrgId=" +
          payload.resellerOrgId +
          "&dealerId=" +
          payload.dealerId +
          "&customerId=" +
          payload.customerId +
          "&orgId=" +
          payload.orgId +
          "&vehicleId=" +
          payload.vehicleId +
          "&size=" +
          payload.size +
          "&page=" +
          payload.page +
          "&startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&selperiod=" +
          payload.selperiod +
          "&orderDir=" +
          payload.sortDir +
          "&search=" +
          payload.search;
        }else{
          var url =
          process.env.REACT_APP_API_BASE_URL +
          "VehicleSummaryReport/vehicleSummaryReportAll.xls?regionId=" +
          payload.regionId +
          "&resellerOrgId=" +
          payload.resellerOrgId +
          "&dealerId=" +
          payload.dealerId +
          "&customerId=" +
          payload.customerId +
          "&orgId=" +
          payload.orgId +
          "&vehicleId=" +
          payload.vehicleId +
          "&size=" +
          payload.size +
          "&page=" +
          payload.page +
          "&startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&selperiod=" +
          payload.selperiod +
          "&orderDir=" +
          payload.sortDir +
          "&search=" +
          payload.search;
        }
          window.open(url);
        
       auditPayload.message = 'Reports >  Vehicle Summary > XLS';
       auditPayload.action = 'View';
       auditPayload.featureName = 'Vehicle Summary';
       auditPayload.customerName = 'All';
       auditPayload.fleetName = 'All';
       auditPayload.result = 'Success';
       auditPayload.access = 'Portal';
       auditPayload.severity = 'INFO';
       auditLog(auditPayload);
       
 
        fetch(url, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          },
        }) // FETCH BLOB FROM IT
          .then((response) => {
            response.blob();
          })
          .then((blob) => {})
          .catch((err) => {});
      } catch (error) {}
    };
    const onChangePDF = async () => {
  
      try {
             var url =
          process.env.REACT_APP_API_BASE_URL +
          "VehicleSummaryReport/vehicleSummaryReportAll.Pdf?regionId=" +
          payload.regionId +
          "&resellerOrgId=" +
          payload.resellerOrgId +
          "&dealerId=" +
          payload.dealerId +
          "&customerId=" +
          payload.customerId +
          "&orgId=" +
          payload.orgId +
          "&vehicleId=" +
          payload.vehicleId +
          "&size=" +
          payload.size +
          "&page=" +
          payload.page +
          "&startDate=" +
          payload.startDate +
          "&selperiod=" +
          payload.selperiod +
          "&endDate=" +
          payload.endDate +
          "&orderDir=" +
          payload.sortDir +
          "&search=" +
          payload.search;
          window.open(url);

  
        auditPayload.message = 'Reports > Vehicle Summary > PDF';
        auditPayload.action = 'View';
        auditPayload.featureName = ' Vehicle Summary';
        auditPayload.customerName = 'All';
        auditPayload.fleetName = 'All';
        auditPayload.result = 'Success';
        auditPayload.access = 'Portal';
        auditPayload.severity = 'INFO';
        auditLog(auditPayload);
      } catch (error) {}
    };
   //Search Text
   const handleSearch = async (searchText) => {
        setNewPage(0);
        setRowsPerPage(25);
        payload.page = 0;
        payload.rowsPerPage = 25;
       payload.search = searchText;
       if(payload.search.length >= 3){
         getOverSpeed(payload);
       }else if(payload.search.length === 0){
         getOverSpeed(payload);
       }
       
   };
   //Page Change
   const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };

   useEffect(() => {
     if(isSuccess){
       setResponseData([]);
       if(vehicleSummaryData.data !== null){
        setResponseData(vehicleSummaryData.data.content);
        setTotalElements(vehicleSummaryData.data.totalElements);
        setTotalPages(vehicleSummaryData.data.totalPages);
      }

    }
  }, [isSuccess]);


  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    var commonPayload={...payload};
    if(newValue === 1){
      navigate('#montraDaywise');
      onSubmitForm(commonPayload);
    }else{
      navigate(location.pathname);
      onSubmitForm(commonPayload);
    }
  };

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Vehicle Summary Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Summary Report"} />
      <Box>
        <FilterDropdown
          getData={onSubmitForm}
          status={false}
          allowDays = {31}
          time = {true}
          last6Month={true}
          allowedMonth = {resellerId === 110224 ? 6 : 3}
          thisMonth={location.hash === "#montraDaywise"?false:true}
          currentMonth={location.hash === "#montraDaywise"?false:true}
        />
        {resellerId === 110224 && (
          <StyledTabBox>
            <StyledTabsGroup
              value={value}
              variant="scrollable"
              onChange={handleChange}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                },
              }}
            >
              <StyledTab label={"Vehicle Summary"} key={1} />
              {
                orgType === 2 &&  <StyledTab label={"Vehicle Summary - Daywise"} key={2} />
              }
            </StyledTabsGroup>
          </StyledTabBox>
        )}
        <StyledCardShadow sx={{ mt: 2 }}>
          {value === 0 && (
            <CardContent sx={{ py: 1 }}>
              <DataTable
                payload={payload}
                exportWithAll={false}
                initialState={initialState1}
                isLoading={isLoading}
                columns={resellerId === 114075 ?columns1
                  :orgType===3?customerColumns:columns}
                data={responseData}
                styles={{ maxWidth: "100vw" }}
                numberOfStickyColumns={1}
                onChangeXcl={onChangeXcl}
                onChangePdf={onChangePDF}
                includeSearchBox={true}
                handleChange={handleSearch}
                page={newPage}
                rowsPerPage={rowsPerPage}
                callbackChangePage={pageChange}
                totalElements={totalElements}
                totalPages={totalPages}
              />
            </CardContent>
          )}
          {value === 1 && (
            <CardContent sx={{ py: 1 }}>
              <Box sx={{ height: "100vh" }}>
                <iframe
                  className="iframe"
                  src={dashboardUrl}
                  width="100%"
                  height="98%"
                  ref={iframeReload}
                ></iframe>
              </Box>
            </CardContent>
          )}
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default VehicleSummaryReport;
