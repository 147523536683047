import React, { useState, useMemo, useEffect } from "react";
import { Box } from "@mui/system";
import { CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { Breadcrumb } from "../shared/Breadcrumbs";
import CommonDropDown from "./LogDropDown";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import LogDropdown from "../LogDropdown/LogDropdown";
import { useDmsDataLogMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const DmsLog = () => {
    const [responseData, setResponseData] = useState([]);
    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
  
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let RoleId = userData.roleId;
  
    const text =
      "This screen shows the VIN assignment to devices with IMEI in the VTS system.";
  
    const columns = useMemo(
      () => [
               {
          Header: "Sent Date",
          accessor: "createdDate",
        },
        {
          Header: "Vin NO",
          accessor: "vinNo",
        },
        {
          Header: "IMEI",
          accessor: "deviceImei",
        },
        {
          Header: "Dealer Name",
          accessor: "dealerLegalName",
        },
        {
          Header: "Customer Name",
          accessor: "customerLegalName",
        },
        {
          Header: "Vehicle Type",
          accessor: "vehicleType",
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          Header: "Description",
          accessor: "description",
        },
      ],
      []
    );
  
    const [auditLog] = useAuditLogMutation();
    let auditPayload = {
      //Audit Common Payload
      orgId: orgId,
      userId: userId, 
      orgName: orgName, 
      userName: userName,
    };
  

    const [vinLogList, { data: vinLogData, isLoading, isSuccess }] =
    useDmsDataLogMutation();
    let commonPayload = {
      columnName: "CREATED_DATE_TIME",
      orderdir: "desc",
    };
    const [payload, setPayload] = useState(null);
    const getData = (data) => {
  
      commonPayload.searchBy = data.searchbyId;
      commonPayload.kycStatus = data.statusId;
      commonPayload.startDate = data.fromData;
      commonPayload.endDate = data.toDate;
      commonPayload.imei = data.iemiId;
      commonPayload.vinVal = data.vinId;
      commonPayload.page = newPage;
      commonPayload.size = rowsPerPage;
      commonPayload.search = "";
      commonPayload.resellerId = resellerId;
      commonPayload.resellerOrgId = resellerId;
       commonPayload.selectedType = "1"
   
      setPayload(commonPayload);
      vinLogList(commonPayload);
    };
  
    //Page Change
    const pageChange = (newPage, rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
      payload.size = rowsPerPage;
      if(totalElements < rowsPerPage * newPage){
        setNewPage(0)
        payload.page = 0;
        vinLogList(payload);
      }else{
        setNewPage(newPage);
        payload.page = newPage;
        vinLogList(payload);
      }
    };
  
    //Search Text
    const handleSearch = async (searchText) => {
      payload.search = searchText;
      if (payload.search.length >= 3) {
        vinLogList(payload);
      } else if (payload.search.length === 0) {
        vinLogList(payload);
      }
    };
  

   
  
    useEffect(() => {
      if (isSuccess) {
        setResponseData([]);
        if (vinLogData.data !== null && vinLogData.data.content.length > 0) {
          setResponseData(vinLogData.data.content);
          console.log(vinLogData);
          setTotalElements(vinLogData.data.totalElements);
          setTotalPages(vinLogData.data.totalPages);
        }
      }
  
      auditPayload.message = "Reports > DMSLog";
      auditPayload.action = "View";
      auditPayload.featureName = "DMSLog";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
   
    }, [isSuccess]);
  
    return (
      <StyledPageLayout>
        <Breadcrumb mainDiv="Logs" subDiv="DMS Log" includePopup={true}></Breadcrumb>
        <MouseOverPopover text={text} title={"DMS Log"} />
        <Box>
          {/* <VinLogDropdown onSubmit={handleSubmit} /> */}
          <LogDropdown getData={getData} allowDays={1}
          allowedMonth={3}/>
          <StyledCardShadow sx={{ mt: 2 }}>
            <CardHeader title={<StyledTableTitle>DMS Log</StyledTableTitle>} />
  
            <CardContent sx={{ py: 0 }}>
              <DataTable
                handleChange={handleSearch}
                isLoading={isLoading}
                includeSearchBox={true}
                includePagination={true}
                columns={columns}
                data={responseData}
                styles={{ maxWidth: "100vw" }}
                numberOfStickyColumns={1}
                page={newPage}
                rowsPerPage={rowsPerPage}
                callbackChangePage={pageChange}
                totalElements={totalElements}
                totalPages={totalPages}
              />
            </CardContent>
          </StyledCardShadow>
        </Box>
      </StyledPageLayout>
    );
};

export default DmsLog;