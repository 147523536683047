import { dashboardApi } from "../ApiSlice";

const LogsApiSlice = dashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    // User Log List
    userLogs: builder.mutation({
      query: (body) => ({
        url: "/UserLog/gridView",
        method: "POST",
        body: body,
      }),
    }),

    //Send Log List
    sendLogList: builder.mutation({
      query: (body) => ({
        url: "/SendLog/gridView",
        method: "POST",
        body: body,
      }),
    }),
    //Audit Logs List
    auditLogList: builder.mutation({
      query: (body) => ({
        url: "/AuditLog/gridView",
        method: "POST",
        body: body,
      }),
    }),

     //Audit Logs List
     auditLogGridView: builder.mutation({
      query: (body) => ({
        url: "/AuditLog/newGridView",
        method: "POST",
        body: body,
      }),
    }),

    //Vin Logs
    vinLogList: builder.mutation({
      query: (body) => ({
        url: "/vehicle/VinLogData",
        method: "POST",
        body: body,
      }),
    }),
    //Airtel Logs
    airtelLogList: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/listAirtelLog",
        method: "POST",
        body: body,
      }),
    }),
    // RefreshOrder-Airtel Log
    refreshOrder: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/refreshOrders",
        method: "POST",
        body: body,
      }),
    }),
    //Feed Log
    feedLogList: builder.mutation({
      query: (body) => ({
        url: "/FeedLog/gridView",
        method: "POST",
        body: body,
      }),
    }),

    // KYC LOGS
    getVin: builder.mutation({
      query: (body) => ({
        url: "vehicle/getGstInForVehicle",
        method: "POST",
        body: body,
      }),
    }),

    //List KYC Feeds
    listKYCFeeds: builder.mutation({
      query: (body) => ({
        url: "KYCLog/getKYCVINList",
        method: "POST",
        body: body,
      }),
    }),

    //List KYC Feeds
    listFeedProcess: builder.mutation({
      query: (body) => ({
        url: "/KYCLog/getKYCVINDetailList",
        method: "POST",
        body: body,
      }),
    }),

    //VIN Journey KYC
    vinJourneyList: builder.mutation({
      query: (body) => ({
        url: "/KYCLog/getVinKYCLogGridData",
        method: "POST",
        body: body,
      }),
    }),

    //Create KYC LOG
    createKYC: builder.mutation({
      query: (body) => ({
        url: "/KYCLog/saveKYCLog",
        method: "POST",
        body: body,
      }),
    }),
    updateKyc: builder.mutation({
      query: (body) => ({
        url: "/KYCLog/updateKYCObject",
        method: "POST",
        body: body,
      }),
    }),
    ///KYCLog/getKYCObject
    getKycDetails: builder.query({
      query: (body) => ({
        url: `KYCLog/getKYCObject?kycFeedId=${body}`,
        method: "GET",
      }),
    }),
    //FeedLOG List
    feedLogList: builder.mutation({
      query: (body) => ({
        url: "/FeedLog/gridView",
        method: "POST",
        body: body,
      }),
    }),

        //CellLOG List
        cellVoltLog: builder.mutation({
          query: (body) => ({
            url: "/ManualCanDataReport/getCellVoltLog",
            method: "POST",
            body: body,
          }),
        }),
        dmsDataLog: builder.mutation({
          query: (body) => ({
            url: "/montra-dms/getDmsList",
            method: "POST",
            body: body,
          }),
        }),
  }),
});
export const {
  useUserLogsMutation,
  useSendLogListMutation,
  useAuditLogListMutation,
  useVinLogListMutation,
  useAirtelLogListMutation,
  useRefreshOrderMutation,
  useGetVinMutation,
  useCreateKYCMutation,
  useUpdateKycMutation,
  useListKYCFeedsMutation,
  useListFeedProcessMutation,
  useVinJourneyListMutation,
  useGetKycDetailsQuery,
  useFeedLogListMutation,
  useCellVoltLogMutation,
  useAuditLogGridViewMutation,
  useDmsDataLogMutation
} = LogsApiSlice;
